.loc{
  position:relative;
  top: 250px;

}

.labelfonts {
   position:relative;
   top: 150px;
}

.form-location{
    position:relative;
    top:-55px;
}