label {
    font-weight: bold;
    display: flex;
    margin-bottom:5px;
    left:150px;
  }

.label-parent {
    font-weight: bold;
    color: goldenrod;
    font-size:large

  }

  
  body{
    background-color:black;
    font-size:x-large;
    font-style: italic;
  }
  
  input[type='text'],
  input[type ='email'],
  textarea {
    display:block; 
    width:100%;
    padding:12px 20px;
    font-size:20px;
    font-weight:bolder;
    margin: 8px 0;
    line-height:1.42857143; 
    color:black;
    background-color: #e4e1e1;
    background-image:none;
    border: 1px solide #ccc; 
     border-radius: 4px;
    
  }
 
   .heading {
    position:relative;
    left:180px;
    top:-50px; 

  }
   
  .location-signup{
    position:relative;
    top: 50px;
    background:black
  } 

  .error {
    color:red;
  }

  .row {
    background:black
  }