.navigation {
    display: flex;
    justify-content:space-between;
    margin: 2%;
    white-space:nowrap
  }
  
  .nav-title {
    font-family: "Splash", cursive;
    color:goldenrod;
   } 
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2px;
     ;
  }
  
    
  .nav-menu a:hover {
    color: whitesmoke;
  }
  
  .logout-button:hover {
    color:goldenrod;
  }
  
  
  .logout-button{
    text-decoration: none;
    color: white;
    font-family: Arial;
  }
  
  .linkStyles  {
    position: "relative";
    display: "inline-block";
    width: "200px";
    padding: "3px";
    color:goldenrod; 
    margin-right: 10px;
    /* top:"120px";
    left:"170px" */
  }  

  .active-Link {
    color:whitesmoke;
  }