.productions-heading {
    text-transform: uppercase;
    font-family:Arial, sans-serif;
    width:70px;
    font-size: 70px;
    line-height: .8;
    margin-left: 5%;
    margin-top: 20%;
    padding-bottom: 5%;
    transform: scale(.7, 1.4);
    color:white
}

.heading-highlight {
    /* color:#42ddf5; */
    color:goldenrod
}


.hero-image {
    position: relative;
    /* z-index: -1; */
    left: 50%;
    margin-top: -15%;  
    filter: brightness(1.0);
}

/* .wide {
    height:900px;
} */

.columns2 {
    position:relative;
    top: 50px;
    right: 90px; 
}

.noWrap {
    flex-wrap: nowrap;
}