.table {
    background-color: wheat;
    white-space: normal;
    border-color: wheat;
}

.event-container {
    position: relative;
    background-color: goldenrod;
    top:150px;
    border:5px;
    font-size:medium;
    font-style: normal;

}

.event-col {
    border:5px;
    border-color: wheat;
    background-color: wheat;
    height:5px;
}

.event-row {
    border: 5px;
    border-color: goldenrod
}