.nameHeading {
    color:whitesmoke
}

.text{
    font-size:large;
    width:1000px;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.containersize {
    position:relative;
    top:150px;
    background-color:goldenrod; 
    width:1025px;
}