.errorMessage {
    position: relative;
    top:-50px; 
    border: 10px solid goldenrod;
    background-color: wheat;
    height: 200px;
    width: 500px;
    justify-self: center;
    left:400px
} 

.text_error {
    color: red;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bolder;
}


.row-not-authorized {
    position: relative;
    top:30px; 
    border: 10px solid goldenrod;
    background-color: wheat;
    height: 250px;
    width: 500px;
    justify-self: center;
    left:400px
}
