.dancerheading {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size:large; 
  }
  
 .nodancers{
   color:red;
 } 
  .subheading {
      position:relative;
      color: black;
      top: -30px;
   
  }
  
  .labelfonts {
    position:relative;
    top:-90px;
    font-size:xx-large;
    left:25px;
  }

  .container {
    position:relative;
    top: 40px;
  }