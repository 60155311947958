
.header {
    background-color: goldenrod;
    height:40px;
}

.main-content{
  background-color:wheat;
  min-height:500px;
  border:5px solid goldenrod;
  /* white-space:nowrap */
 }


.content {
   min-height:500px;
}

.sidebar{
    color:grey;
    border:5px solid goldenrod;
    /* background-color: rgb(33, 37, 41); */
    max-height: 600px;
    overflow-y:scroll
}

.list-group-item{
    background-color: transparent;
    color:#ddd;
}

.footer{
    height:40px;
  
}

.lavish-heading{
    font-family: Jazz LET, fantasy;
}

.portal-container {
    position: relative;
    top:130px;
}

.list-group{
    font-size:15px;
    font-family:Arial, Helvetica, sans-serif
}

#desc {
    font-size:small;
    color:goldenrod;
    font-style:italic
}