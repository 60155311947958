@import url('https://fonts.googleapis.com/css2?family=Splash&display=swap');

*::before, *::after, * {
  padding: 0;
  margin: 0;
}

body {
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-size:large;
}