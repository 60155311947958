.container {
position: relative;
background-color: goldenrod;
top:150px;
border:5px;
font-size:medium;
font-style:normal ;
font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
color:black;
width:800px;
}

.dancerheading{
    font-size: large;
    font-weight:bolder;
}

.nodancers{
    color:red;
}

.subheading{
    position:relative;
    top: -30px;
    font-size: larger;
    font-weight:bolder;
    color:black
}

.dancerheading {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size:xx-large;
    font-weight: bolder;
    color:black
}