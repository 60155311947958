.heading {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.subheading {
    position:relative;
    color: white;
    top: -70px;
 
}

.labelfonts {
  position:relative;
  top:-90px;
  font-size:xx-large;
  left:25px;
}