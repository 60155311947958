.row1{
    position: relative;
    top:150px; 
    border: 10px solid goldenrod;
    background-color: wheat;
    height: 230px;
    width: 500px;
    justify-self: center;
    top:-1000px;
    left:400px
}

.text {
    color: red;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
}

.closebutton{
    position:relative;
    top:250px;
    color:red;
}