.container-size {
    position:relative;
    top:170px;
    font-size:large;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width:800px; 
    background-color:goldenrod;
}

.table-size {
    width:768px;
}